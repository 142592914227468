import axios from 'axios'

const ENDPOINT_PATH = process.env.VUE_APP_BASE_URL;

export default{

    datatable(data) {
        return axios.post(`${ENDPOINT_PATH}/wears/datatable`, data);
    },

    all() {
        return axios.get(`${ENDPOINT_PATH}/wears`);
    },

    exportExcel() {
        return axios.get(`${ENDPOINT_PATH}/wears/export-list`, {responseType: 'blob'});
    },

    show(id) {
        return axios.get(`${ENDPOINT_PATH}/wears/${id}`);
    },

    store(data) {
        return axios.post(`${ENDPOINT_PATH}/wears`, data);
    },

    update(data) {
        return axios.put(`${ENDPOINT_PATH}/wears/${data.id}`, data);
    },

    delete(id) {
        return axios.delete(`${ENDPOINT_PATH}/wears/${id}`);
    },

    tireWearsDatatable(){
        return axios.get(`${ENDPOINT_PATH}/tires/wears`);
    },
    getTireWearById(id){
        return axios.get(`${ENDPOINT_PATH}/tires/wears/${id}`);
    },
    storeTireWear(data) {
        return axios.post(`${ENDPOINT_PATH}/tires/wears/`, data);
    },
    updateTireWear(data) {
        return axios.put(`${ENDPOINT_PATH}/tires/wears/${data.id}`, data);
    },
    deleteTireWear(data){
        return axios.delete(`${ENDPOINT_PATH}/tires/wears/${data.id}`);
    }
}

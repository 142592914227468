import axios from 'axios'

const ENDPOINT_PATH = process.env.VUE_APP_BASE_URL;

export default{
    datatable(data) {
        return axios.post(`${ENDPOINT_PATH}/fuels/datatable`, data);
    },

    all() {
        return axios.get(`${ENDPOINT_PATH}/fuels`);
    },

    exportExcel() {
        return axios.get(`${ENDPOINT_PATH}/fuels/export-list`, {responseType: 'blob'});
    },

    show(id) {
        return axios.get(`${ENDPOINT_PATH}/fuels/${id}`);
    },

    store(data) {
        return axios.post(`${ENDPOINT_PATH}/fuels`, data);
    },

    update(data) {
        return axios.put(`${ENDPOINT_PATH}/fuels/${data.id}`, data);
    },

    delete(id) {
        return axios.delete(`${ENDPOINT_PATH}/fuels/${id}`);
    },

    unitFuelsDatatable(){
        return axios.get(`${ENDPOINT_PATH}/units/fuels`);
    },
    getUnitFuelById(id){
        return axios.get(`${ENDPOINT_PATH}/units/fuels/${id}`);
    },
    storeUnitFuel(data) {
        return axios.post(`${ENDPOINT_PATH}/units/fuels/`, data);
    },
    updateUnitFuel(data) {
        return axios.put(`${ENDPOINT_PATH}/units/fuels/${data.id}`, data);
    },
    deleteUnitFuel(data){
        return axios.delete(`${ENDPOINT_PATH}/units/fuels/${data.id}`);
    }
}

import axios from 'axios'

const ENDPOINT_PATH = process.env.VUE_APP_BASE_URL;

export default {

    datatable(data) {
        return axios.post(`${ENDPOINT_PATH}/measures/datatable`, data);
    },

    all() {
        return axios.get(`${ENDPOINT_PATH}/measures`);
    },

    exportExcel() {
        return axios.get(`${ENDPOINT_PATH}/measures/export-list`, {responseType: 'blob'});
    },

    show(id) {
        return axios.get(`${ENDPOINT_PATH}/measures/${id}`);
    },

    store(data) {
        return axios.post(`${ENDPOINT_PATH}/measures`, data);
    },

    update(data) {
        return axios.put(`${ENDPOINT_PATH}/measures/${data.id}`, data);
    },

    delete(id) {
        return axios.delete(`${ENDPOINT_PATH}/measures/${id}`);
    },


    tireMeasuresDatatable() {
        return axios.get(`${ENDPOINT_PATH}/tires/measures`);
    },
    getTireMeasureById(id) {
        return axios.get(`${ENDPOINT_PATH}/tires/measures/${id}`);
    },
    storeTireMeasure(data) {
        return axios.post(`${ENDPOINT_PATH}/tires/measures/`, data);
    },
    updateTireMeasure(data) {
        return axios.put(`${ENDPOINT_PATH}/tires/measures/${data.id}`, data);
    },
    deleteTireMeasure(data) {
        return axios.delete(`${ENDPOINT_PATH}/tires/measures/${data.id}`);
    }
}

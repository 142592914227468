import Vue from "vue";
import Vuex from "vuex";
import tireBrandService from "@/services/tires/tireBrandService";
import tireDesignService from "@/services/tires/tireDesignService";
import tireMeasureService from "@/services/tires/tireMeasureService";
import tirePartService from "@/services/tires/tirePartService";
import tireFailService from "@/services/tires/tireFailService";
import tireCausalService from "@/services/tires/tireCausalService";
import tireWearService from "@/services/tires/tireWearService";
import tireWearlevelService from "@/services/tires/tireWearlevelService";
import tireOriginService from "@/services/tires/tireOriginService";
import unitBrandService from "@/services/units/unitBrandService";
import unitModelService from "@/services/units/unitModelService";
import unitTypeService from "@/services/units/unitTypeService";
import unitWearLevelService from "@/services/units/unitWearLevelService";
import unitRouteService from "@/services/units/unitRouteService";
import unitFuelService from "@/services/units/unitFuelService";
import otherDocumentService from "@/services/others/otherDocumentService";
import auth from "@/services/auth";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        status: "",
        token: localStorage.getItem("token") || "",
        user: {},
        brands: [],
        designs: [],
        measures: [],
        parts: [],
        fails: [],
        causals: [],
        wears: [],
        wearlevels: [],
        origins: [],
        unitbrands: [],
        unitmodels: [],
        unittypes: [],
        unitwearlevels: [],
        unitroutes: [],
        unitfuels: [],
        documents: [],
    },
    mutations: {
        initialiseStore(state) {
            let user = localStorage.getItem("user");
            if (user) {
                state.user = JSON.parse(user);
            }
        },
        auth_request(state) {
            state.status = 'loading'
        },
        auth_success(state, data) {
            state.status = 'success';
            state.token = data.token;
            localStorage.setItem('user', JSON.stringify(data.user));
            state.user = data.user
        },
        auth_error(state) {
            state.status = 'error'
        },
        logout(state) {
            state.status = ''
            state.token = ''
        },
        setBrands(state, data) {
            state.brands = data;
        },
        setDesigns(state, data) {
            state.designs = data;
        },
        setMeasures(state, data) {
            state.measures = data;
        },
        setParts(state, data) {
            state.parts = data;
        },
        setFails(state, data) {
            state.fails = data;
        },
        setCausals(state, data) {
            state.causals = data;
        },
        setWears(state, data) {
            state.wears = data;
        },
        setWearlevels(state, data) {
            state.wearlevels = data;
        },
        setOrigins(state, data) {
            state.origins = data;
        },
        setUnitbrands(state, data) {
            state.unitbrands = data;
        },
        setUnitmodels(state, data) {
            state.unitmodels = data;
        },
        setUnittypes(state, data) {
            state.unittypes = data;
        },
        setUnitwearlevels(state, data) {
            state.unitwearlevels = data;
        },
        setUnitroutes(state, data) {
            state.unitroutes = data;
        },
        setUnitfuels(state, data) {
            state.unitfuels = data;
        },
        setOtherdocuments(state, data) {
            state.documents = data;
        },
    },
    actions: {
        login({commit}, user) {
            return new Promise((resolve, reject) => {
                commit('auth_request');
                auth.login(user.email, user.password).then(response => {
                    // console.log(response.data);
                    const token = response.data.token;
                    const user = response.data.user;
                    localStorage.setItem('token', token);
                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
                    // console.log('user_send', user);
                    commit('auth_success', {token, user});
                    resolve(response)
                }, err => {
                    commit('auth_error');
                    localStorage.removeItem('token');
                    reject(err)
                })
            })
        },
        logout({commit}) {
            return new Promise((resolve, reject) => {
                commit('logout');
                auth.logout().then(response => {
                    localStorage.removeItem('token');
                    localStorage.removeItem('user');
                    delete axios.defaults.headers.common['Authorization'];
                    resolve()
                }, error => {
                    console.error(error);
                    resolve();
                    localStorage.removeItem('token');
                    localStorage.removeItem('user');
                    delete axios.defaults.headers.common['Authorization'];
                });
            })
        },
        getBrands({commit}) {
            tireBrandService.tireBrandsDatatable().then(response => {
                let {brands} = response.data
                commit('setBrands', brands);
            })
        },
        getDesigns({commit}) {
            tireDesignService.tireDesignsDatatable().then(response => {
                let {designs} = response.data
                commit('setDesigns', designs);
            })
        },
        getMeasures({commit}) {
            tireMeasureService.tireMeasuresDatatable().then(response => {
                let {measures} = response.data
                commit('setMeasures', measures);
            })
        },
        getParts({commit}) {
            tirePartService.tirePartsDatatable().then(response => {
                let {parts} = response.data
                commit('setParts', parts);
            })
        },
        getFails({commit}) {
            tireFailService.tireFailsDatatable().then(response => {
                let {fails} = response.data
                commit('setFails', fails);
            })
        },
        getCausals({commit}) {
            tireCausalService.tireCausalsDatatable().then(response => {
                let {causals} = response.data
                commit('setCausals', causals);
            })
        },
        getWears({commit}) {
            tireWearService.tireWearsDatatable().then(response => {
                let {wears} = response.data
                commit('setWears', wears);
            })
        },
        getWearlevels({commit}) {
            tireWearlevelService.tireWearlevelsDatatable().then(response => {
                let {wearlevels} = response.data
                commit('setWearlevels', wearlevels);
            })
        },
        getOrigins({commit}) {
            tireOriginService.tireOriginsDatatable().then(response => {
                let {origins} = response.data
                commit('setOrigins', origins);
            })
        },
        getUnitBrands({commit}) {
            unitBrandService.unitBrandsDatatable().then(response => {
                let {unitbrands} = response.data
                commit('setUnitbrands', unitbrands);
            })
        },
        getUnitModels({commit}) {
            unitModelService.unitModelsDatatable().then(response => {
                let {unitmodels} = response.data
                commit('setUnitmodels', unitmodels);
            })
        },
        getUnitTypes({commit}) {
            unitTypeService.unitTypesDatatable().then(response => {
                let {unittypes} = response.data
                commit('setUnittypes', unittypes);
            })
        },
        getUnitWearLevels({commit}) {
            unitWearLevelService.unitWearLevelsDatatable().then(response => {
                let {unitwearlevels} = response.data
                commit('setUnitwearlevels', unitwearlevels);
            })
        },
        getUnitRoutes({commit}) {
            unitRouteService.unitRoutesDatatable().then(response => {
                let {unitroutes} = response.data
                commit('setUnitroutes', unitroutes);
            })
        },
        getUnitFuels({commit}) {
            unitFuelService.unitFuelsDatatable().then(response => {
                let {unitfuels} = response.data
                commit('setUnitfuels', unitfuels);
            })
        },
        getOtherDocuments({commit}) {
            otherDocumentService.otherDocumentsDatatable().then(response => {
                let {documents} = response.data
                commit('setOtherdocuments', documents);
            })
        },
        deleteMethod({dispatch}, item) {
            switch (item.method) {
                case 'deleteTireBrand':
                    tireBrandService.deleteTireBrand(item.data).then(() => {
                        dispatch('getBrands');
                    })
                    break;
                case 'deleteTireDesign':
                    tireDesignService.deleteTireDesign(item.data).then(() => {
                        dispatch('getDesigns');
                    })
                    break;
                case 'deleteTireMeasure':
                    tireMeasureService.deleteTireMeasure(item.data).then(() => {
                        dispatch('getMeasures');
                    })
                    break;
                case 'deleteTirePart':
                    tirePartService.deleteTirePart(item.data).then(() => {
                        dispatch('getParts');
                    })
                    break;
                case 'deleteTireFail':
                    tireFailService.deleteTireFail(item.data).then(() => {
                        dispatch('getFails');
                    })
                    break;
                case 'deleteTireCausal':
                    tireCausalService.deleteTireCausal(item.data).then(() => {
                        dispatch('getCausals');
                    })
                    break;
                case 'deleteTireWear':
                    tireWearService.deleteTireWear(item.data).then(() => {
                        dispatch('getWears');
                    })
                    break;
                case 'deleteTireWearlevel':
                    tireWearlevelService.deleteTireWearlevel(item.data).then(() => {
                        dispatch('getWearlevels');
                    })
                    break;
                case 'deleteTireOrigin':
                    tireOriginService.deleteTireOrigin(item.data).then(() => {
                        dispatch('getOrigins');
                    })
                    break;
                case 'deleteUnitBrand':
                    unitBrandService.deleteUnitBrand(item.data).then(() => {
                        dispatch('getUnitBrands');
                    })
                    break;
                case 'deleteUnitModel':
                    unitModelService.deleteUnitModel(item.data).then(() => {
                        dispatch('getUnitModels');
                    })
                    break;
                case 'deleteUnitType':
                    unitTypeService.deleteUnitType(item.data).then(() => {
                        dispatch('getUnitTypes');
                    })
                    break;
                case 'deleteUnitWearLevel':
                    unitWearLevelService.deleteUnitWearLevel(item.data).then(() => {
                        dispatch('getUnitWearLevels');
                    })
                    break;
                case 'deleteUnitRoute':
                    unitRouteService.deleteUnitRoute(item.data).then(() => {
                        dispatch('getUnitRoutes');
                    })
                    break;
                case 'deleteUnitFuel':
                    unitFuelService.deleteUnitFuel(item.data).then(() => {
                        dispatch('getUnitFuels');
                    })
                    break;
                case 'deleteOtherDocument':
                    otherDocumentService.deleteOtherDocument(item.data).then(() => {
                        dispatch('getOtherDocuments');
                    })
                    break;
                default:
                    break;
            }
        },
    },
    getters: {
        isLoggedIn: state => !!state.token,
        authStatus: state => state.status,
        userSession: state => state.user,

        brands: state => state.brands,
        designs: state => state.designs,
        measures: state => state.measures,
        parts: state => state.parts,
        fails: state => state.fails,
        causals: state => state.causals,
        wears: state => state.wears,
        wearlevels: state => state.wearlevels,
        origins: state => state.origins,
        unitbrands: state => state.unitbrands,
        unitmodels: state => state.unitmodels,
        unittypes: state => state.unittypes,
        unitwearlevels: state => state.unitwearlevels,
        unitroutes: state => state.unitroutes,
        unitfuels: state => state.unitfuels,
        documents: state => state.documents
    },
    modules: {}
})

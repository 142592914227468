import axios from 'axios'

const ENDPOINT_PATH = process.env.VUE_APP_BASE_URL;

export default{

    datatable(data) {
        return axios.post(`${ENDPOINT_PATH}/wearlevels/datatable`, data);
    },

    all() {
        return axios.get(`${ENDPOINT_PATH}/wearlevels`);
    },

    exportExcel() {
        return axios.get(`${ENDPOINT_PATH}/wearlevels/export-list`, {responseType: 'blob'});
    },

    show(id) {
        return axios.get(`${ENDPOINT_PATH}/wearlevels/${id}`);
    },

    store(data) {
        return axios.post(`${ENDPOINT_PATH}/wearlevels`, data);
    },

    update(data) {
        return axios.put(`${ENDPOINT_PATH}/wearlevels/${data.id}`, data);
    },

    delete(id) {
        return axios.delete(`${ENDPOINT_PATH}/wearlevels/${id}`);
    },

    tireWearlevelsDatatable(){
        return axios.get(`${ENDPOINT_PATH}/tires/wearlevels`);
    },
    getTireWearlevelById(id){
        return axios.get(`${ENDPOINT_PATH}/tires/wearlevels/${id}`);
    },
    storeTireWearlevel(data) {
        return axios.post(`${ENDPOINT_PATH}/tires/wearlevels/`, data);
    },
    updateTireWearlevel(data) {
        return axios.put(`${ENDPOINT_PATH}/tires/wearlevels/${data.id}`, data);
    },
    deleteTireWearlevel(data){
        return axios.delete(`${ENDPOINT_PATH}/tires/wearlevels/${data.id}`);
    }
}

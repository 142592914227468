import axios from 'axios'

const ENDPOINT_PATH = process.env.VUE_APP_BASE_URL;

export default{
    datatable(data) {
        return axios.post(`${ENDPOINT_PATH}/unit-types/datatable`, data);
    },

    all() {
        return axios.get(`${ENDPOINT_PATH}/unit-types`);
    },

    exportExcel() {
        return axios.get(`${ENDPOINT_PATH}/unit-types/export-list`, {responseType: 'blob'});
    },

    show(id) {
        return axios.get(`${ENDPOINT_PATH}/unit-types/${id}`);
    },

    store(data) {
        return axios.post(`${ENDPOINT_PATH}/unit-types`, data);
    },

    update(data) {
        return axios.put(`${ENDPOINT_PATH}/unit-types/${data.id}`, data);
    },

    delete(id) {
        return axios.delete(`${ENDPOINT_PATH}/unit-types/${id}`);
    },

    unitTypesDatatable(){
        return axios.get(`${ENDPOINT_PATH}/units/types`);
    },
    getUnitTypeById(id){
        return axios.get(`${ENDPOINT_PATH}/units/types/${id}`);
    },
    storeUnitType(data) {
        return axios.post(`${ENDPOINT_PATH}/units/types/`, data);
    },
    updateUnitType(data) {
        return axios.put(`${ENDPOINT_PATH}/units/types/${data.id}`, data);
    },
    deleteUnitType(data){
        return axios.delete(`${ENDPOINT_PATH}/units/types/${data.id}`);
    }
}

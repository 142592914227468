import axios from 'axios'

const ENDPOINT_PATH = process.env.VUE_APP_BASE_URL;

export default{
    datatable(data) {
        return axios.post(`${ENDPOINT_PATH}/origins/datatable`, data);
    },

    all() {
        return axios.get(`${ENDPOINT_PATH}/origins`);
    },

    exportExcel() {
        return axios.get(`${ENDPOINT_PATH}/origins/export-list`, {responseType: 'blob'});
    },

    show(id) {
        return axios.get(`${ENDPOINT_PATH}/origins/${id}`);
    },

    store(data) {
        return axios.post(`${ENDPOINT_PATH}/origins`, data);
    },

    update(data) {
        return axios.put(`${ENDPOINT_PATH}/origins/${data.id}`, data);
    },

    delete(id) {
        return axios.delete(`${ENDPOINT_PATH}/origins/${id}`);
    },

    tireOriginsDatatable(){
        return axios.get(`${ENDPOINT_PATH}/tires/origins`);
    },
    getTireOriginById(id){
        return axios.get(`${ENDPOINT_PATH}/tires/origins/${id}`);
    },
    storeTireOrigin(data) {
        return axios.post(`${ENDPOINT_PATH}/tires/origins/`, data);
    },
    updateTireOrigin(data) {
        return axios.put(`${ENDPOINT_PATH}/tires/origins/${data.id}`, data);
    },
    deleteTireOrigin(data){
        return axios.delete(`${ENDPOINT_PATH}/tires/origins/${data.id}`);
    }
}

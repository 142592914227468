import Vue from 'vue';
import moment from 'moment';

Vue.filter('dateFilter', function(value, format = null) {
    if (value) {
        if (!format) {
            format = 'DD/MM/YYYY hh:mm';
        }
        return moment(String(value)).format(format);
    }
});

import Vue from 'vue';

Vue.directive('intNumber', {
    update(el, binding, vnode) {
        let childData = el.querySelectorAll("input")[0];
        const event = new Event('input', {bubbles: true});
        childData.addEventListener('keyup', function (evt) {
            if (/\D/g.test(childData.value)) {
                childData.value = childData.value.replace(/\D/g, '');
                childData.dispatchEvent(event);
            }
        });
    },
});

import axios from 'axios'

const ENDPOINT_PATH = process.env.VUE_APP_BASE_URL;

export default {

    datatable(data) {
        return axios.post(`${ENDPOINT_PATH}/brand-of-units/datatable`, data);
    },

    all() {
        return axios.get(`${ENDPOINT_PATH}/brand-of-units`);
    },

    exportExcel() {
        return axios.get(`${ENDPOINT_PATH}/brand-of-units/export-list`, {responseType: 'blob'});
    },

    show(id) {
        return axios.get(`${ENDPOINT_PATH}/brand-of-units/${id}`);
    },

    store(data) {
        return axios.post(`${ENDPOINT_PATH}/brand-of-units`, data);
    },

    update(data) {
        return axios.put(`${ENDPOINT_PATH}/brand-of-units/${data.id}`, data);
    },

    delete(id) {
        return axios.delete(`${ENDPOINT_PATH}/brand-of-units/${id}`);
    },

    unitBrandsDatatable() {
        return axios.get(`${ENDPOINT_PATH}/units/brands`);
    },
    getUnitBrandById(id) {
        return axios.get(`${ENDPOINT_PATH}/units/brands/${id}`);
    },
    storeUnitBrand(data) {
        return axios.post(`${ENDPOINT_PATH}/units/brands/`, data);
    },
    updateUnitBrand(data) {
        return axios.put(`${ENDPOINT_PATH}/units/brands/${data.id}`, data);
    },
    deleteUnitBrand(data) {
        return axios.delete(`${ENDPOINT_PATH}/units/brands/${data.id}`);
    }
}

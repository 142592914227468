import axios from 'axios'

const ENDPOINT_PATH = process.env.VUE_APP_BASE_URL;

export default{
    datatable(data) {
        return axios.post(`${ENDPOINT_PATH}/unit-type-axises/datatable`, data);
    },

    all() {
        return axios.get(`${ENDPOINT_PATH}/unit-type-axises`);
    },

    exportExcel() {
        return axios.get(`${ENDPOINT_PATH}/unit-type-axises/export-list`, {responseType: 'blob'});
    },

    show(id) {
        return axios.get(`${ENDPOINT_PATH}/unit-type-axises/${id}`);
    },

    store(data) {
        return axios.post(`${ENDPOINT_PATH}/unit-type-axises`, data);
    },

    update(data) {
        return axios.put(`${ENDPOINT_PATH}/unit-type-axises/${data.id}`, data);
    },

    delete(id) {
        return axios.delete(`${ENDPOINT_PATH}/unit-type-axises/${id}`);
    },

    unitWearLevelsDatatable(){
        return axios.get(`${ENDPOINT_PATH}/units/wearlevels`);
    },
    getUnitWearLevelById(id){
        return axios.get(`${ENDPOINT_PATH}/units/wearlevels/${id}`);
    },
    storeUnitWearLevel(data) {
        return axios.post(`${ENDPOINT_PATH}/units/wearlevels/`, data);
    },
    updateUnitWearLevel(data) {
        return axios.put(`${ENDPOINT_PATH}/units/wearlevels/${data.id}`, data);
    },
    deleteUnitWearLevel(data){
        return axios.delete(`${ENDPOINT_PATH}/units/wearlevels/${data.id}`);
    }
}

import Vue from "vue";
import App from "./App.vue";
import Vuex from "vuex"
import router from "./router";
import vuetify from "./plugins/vuetify";
import "./directives";
import "./filters";
import VueSidebarMenu from "vue-sidebar-menu";
import "vue-sidebar-menu/dist/vue-sidebar-menu.css";
import store from "./store";
import Axios from "axios";
// import { makeServer } from "./server"
import './styles/index.css';

Vue.config.productionTip = false;
Vue.prototype.$http = Axios;
const token = localStorage.getItem("token");
if (token) {
    Vue.prototype.$http.defaults.headers.common["Authorization"] = 'Bearer ' + token
}

// if (process.env.NODE_ENV === "development") {
//   makeServer()
// }

Vue.prototype.$http = Axios;
// Vue.prototype.$isLoggedIn = { value: true };
Vue.use(VueSidebarMenu);
Vue.use(Vuex);
new Vue({
    router,
    store,
    beforeCreate() {
        this.$store.commit('initialiseStore');
    },
    vuetify,
    render: (h) => h(App),
}).$mount("#app");

import axios from 'axios'

const ENDPOINT_PATH = process.env.VUE_APP_BASE_URL;

export default {

    datatable(data) {
        return axios.post(`${ENDPOINT_PATH}/brands/datatable`, data);
    },

    all(retread = null) {
        let url = 'brands';
        if (retread != null) {
            url += '?retread=' + retread;
        }
        return axios.get(`${ENDPOINT_PATH}/${url}`);
    },

    exportExcel() {
        return axios.get(`${ENDPOINT_PATH}/brands/export-list`, {responseType: 'blob'});
    },

    show(id) {
        return axios.get(`${ENDPOINT_PATH}/brands/${id}`);
    },

    store(data) {
        return axios.post(`${ENDPOINT_PATH}/brands`, data);
    },

    update(data) {
        return axios.put(`${ENDPOINT_PATH}/brands/${data.id}`, data);
    },

    delete(id) {
        return axios.delete(`${ENDPOINT_PATH}/brands/${id}`);
    },


    tireBrandsDatatable() {
        return axios.get(`${ENDPOINT_PATH}/tires/brands`);
    },

    getTireBrandById(id) {
        return axios.get(`${ENDPOINT_PATH}/tires/brands/${id}`);
    },
    storeTireBrand(data) {
        return axios.post(`${ENDPOINT_PATH}/tires/brands/`, data);
    },
    updateTireBrand(data) {
        return axios.put(`${ENDPOINT_PATH}/tires/brands/${data.id}`, data);
    },
    deleteTireBrand(data) {
        return axios.delete(`${ENDPOINT_PATH}/tires/brands/${data.id}`);
    }
}

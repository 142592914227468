import axios from 'axios'

const ENDPOINT_PATH = process.env.VUE_APP_BASE_URL;

export default{

    datatable(data) {
        return axios.post(`${ENDPOINT_PATH}/unit-models/datatable`, data);
    },

    all() {
        return axios.get(`${ENDPOINT_PATH}/unit-models`);
    },

    exportExcel() {
        return axios.get(`${ENDPOINT_PATH}/unit-models/export-list`, {responseType: 'blob'});
    },

    allByBrand(brandId) {
        return axios.get(`${ENDPOINT_PATH}/unit-models-by-brand?brand_of_unit_id=${brandId}`);
    },


    show(id) {
        return axios.get(`${ENDPOINT_PATH}/unit-models/${id}`);
    },

    store(data) {
        return axios.post(`${ENDPOINT_PATH}/unit-models`, data);
    },

    update(data) {
        return axios.put(`${ENDPOINT_PATH}/unit-models/${data.id}`, data);
    },

    delete(id) {
        return axios.delete(`${ENDPOINT_PATH}/unit-models/${id}`);
    },

    unitModelsDatatable(){
        return axios.get(`${ENDPOINT_PATH}/units/models`);
    },
    getUnitModelById(id){
        return axios.get(`${ENDPOINT_PATH}/units/models/${id}`);
    },
    storeUnitModel(data) {
        return axios.post(`${ENDPOINT_PATH}/units/models/`, data);
    },
    updateUnitModel(data) {
        return axios.put(`${ENDPOINT_PATH}/units/models/${data.id}`, data);
    },
    deleteUnitModel(data){
        return axios.delete(`${ENDPOINT_PATH}/units/models/${data.id}`);
    }
}

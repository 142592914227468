import axios from "axios";

const ENDPOINT_PATH = process.env.VUE_APP_BASE_URL;

export default {
    login(email, password) {
        const user = {email, password};
        return axios.post(`${ENDPOINT_PATH}/auth/login/admin-customer-inspector`, user);
    },

    logout() {
        return axios.post(`${ENDPOINT_PATH}/auth/logout`);
    },

    sendResetLinkEmail(form) {
        return axios.post(`${ENDPOINT_PATH}/auth/password/email`, form);
    },

    resetPassword(form) {
        return axios.post(`${ENDPOINT_PATH}/auth/password/reset`, form);
    }
};

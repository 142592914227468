import axios from 'axios'

const ENDPOINT_PATH = process.env.VUE_APP_BASE_URL;

export default {

    datatable(data) {
        return axios.post(`${ENDPOINT_PATH}/fails/datatable`, data);
    },

    all(partId) {
        if (!partId) {
            partId = '';
        }
        return axios.get(`${ENDPOINT_PATH}/fails?part_id=${partId}`);
    },

    exportExcel() {
        return axios.get(`${ENDPOINT_PATH}/fails/export-list`, {responseType: 'blob'});
    },

    show(id) {
        return axios.get(`${ENDPOINT_PATH}/fails/${id}`);
    },

    store(data) {
        return axios.post(`${ENDPOINT_PATH}/fails`, data);
    },

    update(data) {
        return axios.put(`${ENDPOINT_PATH}/fails/${data.id}`, data);
    },

    delete(id) {
        return axios.delete(`${ENDPOINT_PATH}/fails/${id}`);
    },

    tireFailsDatatable() {
        return axios.get(`${ENDPOINT_PATH}/tires/fails`);
    },
    getTireFailById(id) {
        return axios.get(`${ENDPOINT_PATH}/tires/fails/${id}`);
    },
    storeTireFail(data) {
        return axios.post(`${ENDPOINT_PATH}/tires/fails/`, data);
    },
    updateTireFail(data) {
        return axios.put(`${ENDPOINT_PATH}/tires/fails/${data.id}`, data);
    },
    deleteTireFail(data) {
        return axios.delete(`${ENDPOINT_PATH}/tires/fails/${data.id}`);
    }
}

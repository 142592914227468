import axios from 'axios'

const ENDPOINT_PATH = process.env.VUE_APP_BASE_URL;

export default{

    datatable(data) {
        return axios.post(`${ENDPOINT_PATH}/parts/datatable`, data);
    },

    all() {
        return axios.get(`${ENDPOINT_PATH}/parts`);
    },

    exportExcel() {
        return axios.get(`${ENDPOINT_PATH}/parts/export-list`, {responseType: 'blob'});
    },

    show(id) {
        return axios.get(`${ENDPOINT_PATH}/parts/${id}`);
    },

    store(data) {
        return axios.post(`${ENDPOINT_PATH}/parts`, data);
    },

    update(data) {
        return axios.put(`${ENDPOINT_PATH}/parts/${data.id}`, data);
    },

    delete(id) {
        return axios.delete(`${ENDPOINT_PATH}/parts/${id}`);
    },


    tirePartsDatatable(){
        return axios.get(`${ENDPOINT_PATH}/tires/parts`);
    },
    getTirePartById(id){
        return axios.get(`${ENDPOINT_PATH}/tires/parts/${id}`);
    },
    storeTirePart(data) {
        return axios.post(`${ENDPOINT_PATH}/tires/parts/`, data);
    },
    updateTirePart(data) {
        return axios.put(`${ENDPOINT_PATH}/tires/parts/${data.id}`, data);
    },
    deleteTirePart(data){
        return axios.delete(`${ENDPOINT_PATH}/tires/parts/${data.id}`);
    }
}

import axios from 'axios'

const ENDPOINT_PATH = process.env.VUE_APP_BASE_URL;

export default{
    datatable(data) {
        return axios.post(`${ENDPOINT_PATH}/routes/datatable`, data);
    },

    all() {
        return axios.get(`${ENDPOINT_PATH}/routes`);
    },

    exportExcel() {
        return axios.get(`${ENDPOINT_PATH}/routes/export-list`, {responseType: 'blob'});
    },

    show(id) {
        return axios.get(`${ENDPOINT_PATH}/routes/${id}`);
    },

    store(data) {
        return axios.post(`${ENDPOINT_PATH}/routes`, data);
    },

    update(data) {
        return axios.put(`${ENDPOINT_PATH}/routes/${data.id}`, data);
    },

    delete(id) {
        return axios.delete(`${ENDPOINT_PATH}/routes/${id}`);
    },

    unitRoutesDatatable(){
        return axios.get(`${ENDPOINT_PATH}/units/routes`);
    },
    getUnitRouteById(id){
        return axios.get(`${ENDPOINT_PATH}/units/routes/${id}`);
    },
    storeUnitRoute(data) {
        return axios.post(`${ENDPOINT_PATH}/units/routes/`, data);
    },
    updateUnitRoute(data) {
        return axios.put(`${ENDPOINT_PATH}/units/routes/${data.id}`, data);
    },
    deleteUnitRoute(data){
        return axios.delete(`${ENDPOINT_PATH}/units/routes/${data.id}`);
    }
}

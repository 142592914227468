<template>
   <v-app :style="{background: $vuetify.theme.themes[theme].background}">
    <AppBar v-if="isLoggedIn"/>
    <Sidebar v-if="isLoggedIn"/>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<style>
.v-menu-responsive {
  display: none !important;
}
@media (max-width: 768px) {
  .v-menu {
    display: none !important;
  }
  .v-navigation-drawer-fs {
    display: none !important;
  }
  .v-menu-responsive {
    display: block !important;
  }
  .v-main {
    padding: 20px 0px 20px 0px !important;
  }
}
</style>
<script>
import Sidebar from "@/components/layout/Sidebar";
import AppBar from "@/components/layout/AppBar";

export default {
  name: "App",

  components: {
      Sidebar,
      AppBar,
  },

  data: () => ({
    //
  }),
  computed: {
      isLoggedIn : function(){
          return this.$store.getters.isLoggedIn
      },
    theme(){
      return this.isLoggedIn ? 'dark' : 'light'
    }
  },
};
</script>

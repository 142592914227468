import Vue from 'vue';

Vue.directive('number', {
    update(el, binding, vnode) {
        let childData = el.querySelectorAll("input")[0];
        const event = new Event('input', {bubbles: true});
        childData.addEventListener('keyup', function (evt) {
            childData.value = childData.value.replace(/[a-zA-Z]+/g, '').replace('.', '');
            childData.dispatchEvent(event);
        });
    },
});

import Vue from "vue";
import VueRouter from "vue-router";
import store from '@/store/index';
Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Maintenance",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(
                /* webpackChunkName: "about" */
                "../views/Maintenance.vue"),
    },
    {
        path: "/process",
        name: "Process",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/Process.vue"),
    },
    {
        path: "/process/fuel",
        name: "Fuel",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/process/fuel/Fuel.vue"),
    },
    {
        path: "/process/fuel/edit/:id",
        name: "ProcessFuelEdit",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/process/fuel/fuel-add-edit/FuelEdit.vue"),
    },
    {
        path: "/process/fuel/create",
        name: "ProcessFuelAdd",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/process/fuel/fuel-add-edit/FuelAdd.vue"),
    },
    {
        path: "/process/pesaje",
        name: "Pesaje",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/process/pesaje/Pesaje.vue"),
    },
    {
        path: "/process/pesaje/edit/:id",
        name: "ProcessPesajeEdit",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/process/pesaje/pesaje-add-edit/PesajeEdit.vue"),
    },
    {
        path: "/process/pesaje/create",
        name: "ProcessPesajeAdd",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/process/pesaje/pesaje-add-edit/PesajeAdd.vue"),
    },
    {
        path: "/process/movements",
        name: "Movements",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/process/movements/Movements.vue"),
    },
    {
        path: "/process/inspections",
        name: "Inspection",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/process/inspections/Inspection.vue"),
    },
    {
        path: "/process/inspections/create",
        name: "InspectionAdd",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/process/inspections/inspection-add-edit/InspectionAdd.vue"),
    },
    {
        path: "/process/inspections/edit/:id",
        name: "InspectionEdit",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/process/inspections/inspection-add-edit/InspectionEdit.vue"),
    },
    {
        path: "/reports",
        name: "Report",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/Report.vue"),
    },
    {
        path: "/reports/inspections/audit",
        name: "AuditInspection",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/reports/inspections/AuditInspection.vue"),
    },
    {
        path: "/reports/irregular-wear",
        name: "IrregularWear",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/reports/inspections/IrregularWear.vue"),
    },
    {
        path: "/reports/tires/travel",
        name: "TireTravel",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/reports/tires/TireTravel.vue"),
    },
    {
        path: "/reports/tires/finals",
        name: "FinalsReport",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/reports/tires/FinalsReport.vue"),
    },
    {
        path: "/reports/tires/scrap",
        name: "Scrap",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/reports/tires/Scrap.vue"),
    },
    {
        path: "/reports/projections",
        name: "Projection",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/reports/projections/Projection.vue"),
    },
    {
        path: "/reports/fuel/vehicles",
        name: "FuelPerVehicle",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/reports/fuels/FuelPerVehicle.vue"),
    },
    {
        path: "/reports/fuel/tires",
        name: "FuelPerTire",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/reports/fuels/FuelPerTire.vue"),
    },
    {
        path: "/administration",
        name: "Administration",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/Administration.vue"),
    },
    {
        path: "/administration/customers",
        name: "Customer",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/administrations/customer/Customer.vue"),
    },
    {
        path: "/administration/customer/edit/:id",
        name: "CustomerEdit",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/administrations/customer/customer-add-edit/CustomerEdit.vue"),
    },
    {
        path: "/administration/customer/create",
        name: "CustomerAdd",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/administrations/customer/customer-add-edit/CustomerAdd.vue"),
    },
    {
        path: "/administration/mobile-apps",
        name: "MobileApp",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/administrations/mobile-app/MobileApp.vue"),
    },
    {
        path: "/administration/mobile-apps/edit/:id",
        name: "MobileAppEdit",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/administrations/mobile-app/mobile-app-add-edit/MobileAppEdit.vue"),
    },
    {
        path: "/administration/mobile-apps/create",
        name: "MobileAppAdd",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/administrations/mobile-app/mobile-app-add-edit/MobileAppAdd.vue"),
    },
    {
        path: "/administration/inspectors",
        name: "Inspector",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/administrations/inspector/Inspector.vue"),
    },
    {
        path: "/administration/inspectors/create",
        name: "InspectorAdd",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/administrations/inspector/inspector-add-edit/InspectorAdd.vue"),
    },
    {
        path: "/administration/inspectors/edit/:id",
        name: "InspectorEdit",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/administrations/inspector/inspector-add-edit/InspectorEdit.vue"),
    },
    {
        path: "/administration/customer/user",
        name: "UserCustomer",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/administrations/customer-user/UserCustomer.vue"),
    },
    {
        path: "/administration/customer/user/create",
        name: "CustomerUserAdd",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/administrations/customer-user/customer-user-add-edit/CustomerUserAdd.vue"),
    },
    {
        path: "/administration/customer/user/edit/:id",
        name: "CustomerUserEdit",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/administrations/customer-user/customer-user-add-edit/CustomerUserEdit.vue"),
    },
    {
        path: "/administration/administrators",
        name: "Inspector",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/administrations/administrator/Administrator.vue"),
    },
    {
        path: "/administration/administrators/create",
        name: "InspectorAdd",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/administrations/administrator/administrator-add-edit/AdministratorAdd.vue"),
    },
    {
        path: "/administration/administrators/edit/:id",
        name: "InspectorEdit",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/administrations/administrator/administrator-add-edit/AdministratorEdit.vue"),
    },
    {
        path: "/window",
        name: "Window",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/Window.vue"),
    },
    {
        path: "/tires",
        name: "Tires",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/tires/Tires.vue"),
    },
    {
        path: "/tires/create",
        name: "TireAdd",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/tires/tire-add-edit/TireAdd.vue"),
    },
    {
        path: "/tires/edit/:id",
        name: "TireEdit",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/tires/tire-add-edit/TireEdit.vue"),
    },
    {
        path: "/tires/brands",
        name: "TiresBrand",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/tires/brand/Brand.vue"),
    },
    {
        path: "/tires/brands/edit/:id",
        name: "TiresBrandEdit",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/tires/brand/brand-add-edit/BrandEdit.vue"),
    },
    {
        path: "/tires/brands/create",
        name: "TiresBrandAdd",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/tires/brand/brand-add-edit/BrandAdd.vue"),
    },
    {
        path: "/tires/designs",
        name: "TiresDesign",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/tires/design/Design.vue"),
    },
    {
        path: "/tires/designs/edit/:id",
        name: "TiresDesignEdit",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/tires/design/design-add-edit/DesignEdit.vue"),
    },
    {
        path: "/tires/designs/create",
        name: "TiresDesignAdd",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/tires/design/design-add-edit/DesignAdd.vue"),
    },
    {
        path: "/tires/measures",
        name: "TiresMeasure",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/tires/measure/Measure.vue"),
    },
    {
        path: "/tires/measures/edit/:id",
        name: "TiresMeasureEdit",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/tires/measure/measure-add-edit/MeasureEdit.vue"),
    },
    {
        path: "/tires/measures/create",
        name: "TiresMeasureAdd",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/tires/measure/measure-add-edit/MeasureAdd.vue"),
    },
    {
        path: "/tires/parts",
        name: "TiresPart",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/tires/part/Part.vue"),
    },
    {
        path: "/tires/parts/edit/:id",
        name: "TiresPartEdit",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/tires/part/part-add-edit/PartEdit.vue"),
    },
    {
        path: "/tires/parts/create",
        name: "TiresPartAdd",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/tires/part/part-add-edit/PartAdd.vue"),
    },
    {
        path: "/tires/fails",
        name: "TiresFail",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/tires/fail/Fail.vue"),
    },
    {
        path: "/tires/fails/edit/:id",
        name: "TiresFailEdit",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/tires/fail/fail-add-edit/FailEdit.vue"),
    },
    {
        path: "/tires/fails/create",
        name: "TiresFailAdd",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/tires/fail/fail-add-edit/FailAdd.vue"),
    },
    {
        path: "/tires/causals",
        name: "TiresCausal",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/tires/causal/Causal.vue"),
    },
    {
        path: "/tires/causals/edit/:id",
        name: "TiresCausalEdit",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/tires/causal/causal-add-edit/CausalEdit.vue"),
    },
    {
        path: "/tires/causals/create",
        name: "TiresCausalAdd",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/tires/causal/causal-add-edit/CausalAdd.vue"),
    },
    {
        path: "/tires/wears",
        name: "TiresWear",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/tires/wear/Wear.vue"),
    },
    {
        path: "/tires/wears/edit/:id",
        name: "TiresWearEdit",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/tires/wear/wear-add-edit/WearEdit.vue"),
    },
    {
        path: "/tires/wears/create",
        name: "TiresWearAdd",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/tires/wear/wear-add-edit/WearAdd.vue"),
    },
    {
        path: "/tires/wearlevels",
        name: "TiresWearlevel",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/tires/wearlevel/Wearlevel.vue"),
    },
    {
        path: "/tires/wearlevels/edit/:id",
        name: "TiresWearlevelEdit",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/tires/wearlevel/wearlevel-add-edit/WearlevelEdit.vue"),
    },
    {
        path: "/tires/wearlevels/create",
        name: "TiresWearlevelAdd",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/tires/wearlevel/wearlevel-add-edit/WearlevelAdd.vue"),
    },
    {
        path: "/tires/pressure",
        name: "Pressure",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/tires/pressure/Pressure.vue"),
    },
    {
        path: "/tires/pressure/edit/:id",
        name: "PressureEdit",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/tires/pressure/pressure-add-edit/PressureEdit.vue"),
    },
    {
        path: "/tires/pressure/create",
        name: "PressureAdd",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/tires/pressure/pressure-add-edit/PressureAdd.vue"),
    },
    {
        path: "/tires/origins",
        name: "TiresOrigin",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/tires/origin/Origin.vue"),
    },
    {
        path: "/tires/origins/edit/:id",
        name: "TiresOriginEdit",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/tires/origin/origin-add-edit/OriginEdit.vue"),
    },
    {
        path: "/tires/origins/create",
        name: "TiresOriginAdd",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/tires/origin/origin-add-edit/OriginAdd.vue"),
    },
    {
        path: "/tires/design-measurements",
        name: "DesignMeasurement",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/tires/design-measurement/DesignMeasurement.vue"),
    },
    {
        path: "/tires/design-measurements/edit/:id",
        name: "DesignMeasurementEdit",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/tires/design-measurement/design-measurement-add-edit/DesignMeasurementEdit.vue"),
    },
    {
        path: "/tires/design-measurements/create",
        name: "DesignMeasurementAdd",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/tires/design-measurement/design-measurement-add-edit/DesignMeasurementAdd.vue"),
    },
    {
        path: "/units",
        name: "Units",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/units/Units.vue"),
    },
    {
        path: "/units/create",
        name: "UnitAdd",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/units/unit-add-edit/UnitAdd.vue"),
    },
    {
        path: "/units/edit/:id",
        name: "UnitEdit",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/units/unit-add-edit/UnitEdit.vue"),
    },
    {
        path: "/units/tires/:id",
        name: "UnitTires",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/units/unit-tires/UnitTires.vue"),
    },
    {
        path: "/units/brands",
        name: "UnitBrand",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/units/brand/UnitBrand.vue"),
    },
    {
        path: "/units/brands/edit/:id",
        name: "UnitBrandEdit",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/units/brand/unitbrand-add-edit/UnitBrandEdit.vue"),
    },
    {
        path: "/units/brands/create",
        name: "UnitBrandAdd",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/units/brand/unitbrand-add-edit/UnitBrandAdd.vue"),
    },
    {
        path: "/units/models",
        name: "UnitModel",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/units/model/UnitModel.vue"),
    },
    {
        path: "/units/models/edit/:id",
        name: "UnitModelEdit",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/units/model/unitmodel-add-edit/UnitModelEdit.vue"),
    },
    {
        path: "/units/models/create",
        name: "UnitModelAdd",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/units/model/unitmodel-add-edit/UnitModelAdd.vue"),
    },
    {
        path: "/units/types",
        name: "UnitType",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/units/type/UnitType.vue"),
    },
    {
        path: "/units/types/edit/:id",
        name: "UnitTypeEdit",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/units/type/unittype-add-edit/UnitTypeEdit.vue"),
    },
    {
        path: "/units/types/create",
        name: "UnitTypeAdd",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/units/type/unittype-add-edit/UnitTypeAdd.vue"),
    },
    {
        path: "/units/wearlevels",
        name: "UnitWearLevel",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/units/wearlevel/UnitWearLevel.vue"),
    },
    {
        path: "/units/wearlevels/edit/:id",
        name: "UnitWearLevelEdit",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/units/wearlevel/wearlevel-add-edit/UnitWearLevelEdit.vue"),
    },
    {
        path: "/units/wearlevels/create",
        name: "UnitWearLevelAdd",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/units/wearlevel/wearlevel-add-edit/UnitWearLevelAdd.vue"),
    },
    {
        path: "/units/routes",
        name: "UnitRoute",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/units/route/UnitRoute.vue"),
    },
    {
        path: "/units/routes/edit/:id",
        name: "UnitRouteEdit",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/units/route/route-add-edit/UnitRouteEdit.vue"),
    },
    {
        path: "/units/routes/create",
        name: "UnitRouteAdd",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/units/route/route-add-edit/UnitRouteAdd.vue"),
    },
    {
        path: "/units/fuels",
        name: "UnitFuel",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/units/fuel/UnitFuel.vue"),
    },
    {
        path: "/units/fuels/edit/:id",
        name: "UnitFuelEdit",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/units/fuel/fuel-add-edit/UnitFuelEdit.vue"),
    },
    {
        path: "/units/fuels/create",
        name: "UnitFuelAdd",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/units/fuel/fuel-add-edit/UnitFuelAdd.vue"),
    },
    {
        path: "/drivers",
        name: "Drivers",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/drivers/Drivers.vue"),
    },
    {
        path: "/drivers/create",
        name: "DriverAdd",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/drivers/driver-add-edit/DriverAdd.vue"),
    },
    {
        path: "/drivers/edit/:id",
        name: "DriverEdit",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/drivers/driver-add-edit/DriverEdit.vue"),
    },
    {
        path: "/fleets",
        name: "Fleets",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/fleets/Fleets.vue"),
    },
    {
        path: "/fleets/create",
        name: "FleetAdd",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/fleets/fleet-add-edit/FleetAdd.vue"),
    },
    {
        path: "/fleets/edit/:id",
        name: "FleetEdit",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/fleets/fleet-add-edit/FleetEdit.vue"),
    },
    {
        path: "/others/documents",
        name: "OtherDocument",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/others/document/OtherDocument.vue"),
    },
    {
        path: "/others/documents/edit/:id",
        name: "OtherDocumentEdit",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/others/document/document-add-edit/OtherDocumentEdit.vue"),
    },
    {
        path: "/others/documents/create",
        name: "OtherDocumentAdd",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/others/document/document-add-edit/OtherDocumentAdd.vue"),
    },
    {
        path: "/inspector/mobile-app",
        name: "InspectorMobileApp",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/inspector-section/mobile-app/InspectorMobileApp.vue"),
    },
    {
        path: "/recover-password",
        name: "RecoverPassword",
        meta: {
            requiresGuest: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/auth/RecoverPassword.vue"),
    },
    {
        path: "/password/reset",
        name: "ResetPassword",
        meta: {
            requiresGuest: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/auth/ResetPassword.vue"),
    },
    {
        path: "/login",
        name: "Login",
        meta: {
            requiresGuest: true,
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/auth/Login.vue"),
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes: routes,
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.isLoggedIn) {
            next();
            return;
        }
        next('/login')
    } else if (to.matched.some(record => record.meta.requiresGuest)) {
        if (store.getters.isLoggedIn) {
            next('/');
            return;
        }
        next()
    } else {
        next()
    }
});

export default router;

import axios from 'axios'

const ENDPOINT_PATH = process.env.VUE_APP_BASE_URL;

export default{

    datatable(data) {
        return axios.post(`${ENDPOINT_PATH}/designs/datatable`, data);
    },

    all() {
        return axios.get(`${ENDPOINT_PATH}/designs`);
    },

    exportExcel() {
        return axios.get(`${ENDPOINT_PATH}/designs/export-list`, {responseType: 'blob'});
    },

    allByBrand(brandId) {
        return axios.get(`${ENDPOINT_PATH}/designs-by-brand?brand_id=${brandId}`);
    },

    show(id) {
        return axios.get(`${ENDPOINT_PATH}/designs/${id}`);
    },

    store(data) {
        return axios.post(`${ENDPOINT_PATH}/designs`, data);
    },

    update(data) {
        return axios.put(`${ENDPOINT_PATH}/designs/${data.id}`, data);
    },

    delete(id) {
        return axios.delete(`${ENDPOINT_PATH}/designs/${id}`);
    },


    tireDesignsDatatable(){
        return axios.get(`${ENDPOINT_PATH}/tires/designs`);
    },
    getTireDesignById(id){
        return axios.get(`${ENDPOINT_PATH}/tires/designs/${id}`);
    },
    storeTireDesign(data) {
        return axios.post(`${ENDPOINT_PATH}/tires/designs/`, data);
    },
    updateTireDesign(data) {
        return axios.put(`${ENDPOINT_PATH}/tires/designs/${data.id}`, data);
    },
    deleteTireDesign(data){
        return axios.delete(`${ENDPOINT_PATH}/tires/designs/${data.id}`);
    }
}

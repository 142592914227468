import axios from 'axios'

const ENDPOINT_PATH = process.env.VUE_APP_BASE_URL;

export default{
    datatable(data) {
        return axios.post(`${ENDPOINT_PATH}/document-types/datatable`, data);
    },

    all() {
        return axios.get(`${ENDPOINT_PATH}/document-types`);
    },

    exportExcel() {
        return axios.get(`${ENDPOINT_PATH}/document-types/export-list`, {responseType: 'blob'});
    },

    show(id) {
        return axios.get(`${ENDPOINT_PATH}/document-types/${id}`);
    },

    store(data) {
        return axios.post(`${ENDPOINT_PATH}/document-types`, data);
    },

    update(data) {
        return axios.put(`${ENDPOINT_PATH}/document-types/${data.id}`, data);
    },

    delete(id) {
        return axios.delete(`${ENDPOINT_PATH}/document-types/${id}`);
    },

    otherDocumentsDatatable(){
        return axios.get(`${ENDPOINT_PATH}/others/documents`);
    },
    getOtherDocumentById(id){
        return axios.get(`${ENDPOINT_PATH}/others/documents/${id}`);
    },
    storeOtherDocument(data) {
        return axios.post(`${ENDPOINT_PATH}/others/documents/`, data);
    },
    updateOtherDocument(data) {
        return axios.put(`${ENDPOINT_PATH}/others/documents/${data.id}`, data);
    },
    deleteOtherDocument(data){
        return axios.delete(`${ENDPOINT_PATH}/others/documents/${data.id}`);
    }
}
